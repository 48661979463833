const $ = window.jQuery
import 'jquery-match-height'
import mobileMenu from '../components/mobile'
import Accordions from '../components/accordions'
import ProductCategories from '../components/accordions/productCategories'
import supportSection from '../components/support'

export default {
  init() {

    new mobileMenu();
    if( $('.support__container').length || $('.image-ctas').length ) new supportSection();
    if( $('.accordion__single').length > 1 ) new Accordions();
    if( $('.product-category__content-areas .widget').length > 1 ) new ProductCategories();

    $( '.slideshow .slideshow__slides' ).slick({
      'arrows': false,
      'dots': true,
      'rows': 0,
    });

    $( '.featured-products .featured-products__product__image' ).matchHeight();
    $( '.upsells .product img' ).matchHeight();

    $( '[data-fancybox="contentGallery"]' ).fancybox({
      'loop': true,
      'gutter': 0,
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
