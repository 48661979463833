
export default class mobileMenu {
  constructor() {
    this.primaryNavigationContainer = document.getElementById( 'primaryHeaderNavigation' );
    this.mobileMenuContainer = document.getElementById( 'mobileNavigationMenu' );
    this.navigationInnerHtml = this.primaryNavigationContainer.innerHTML;

    this.initMenu();
  }
  initMenu() {
    this.mobileMenuContainer.innerHTML = this.navigationInnerHtml;
    $( '#mobileMenuButton' ).on( 'click', (e) => this.toggleMenu(e) );
    $( '#mobileNavigationClose' ).on( 'click', (e) => this.toggleMenu(e) );
  }
  toggleMenu(e) {
    $( 'body' ).toggleClass( 'mobile-menu-open' );
    $( '.mobile-navigation__container' ).toggleClass( 'mobile-navigation__container--open' );
    e.preventDefault();
  }
}