import getCurrency from '../../util/getCurrency';
import Api from '../Api/Api';

const api = new Api();

function isBetween(from, to, num) {
  return from <= num && to >= num;
}

export default class GroupProduct
{
  constructor(group)
  {
    group = $(group);

    if (!this.isGroupProduct(group)) {
      throw new Error('Element is not a product group');
    }

    this._group = group;
    this._attributeValues = {};
    this._productVariation = false;
    this._isLoading = false;
  }

  get loading()
  {
    return this._isLoading;
  }

  set loading(loading)
  {
    this._isLoading = loading;

    $('.woocommerce-group-tabs-content').toggleClass('loading', loading);
  }

  get price()
  {
    const qty = this.quantity;

    if (!this.quantity) {
      return getCurrency(0);
    }

    const price = !this.variation
      ? this._group.data('price')
      : this.variation.price;

    const mixingFee = this.mixingFee;

    const tubePrice = this.tubePrice;

    return getCurrency(price * qty + mixingFee + tubePrice);
  }

  get salePrice()
  {
    if (!this.variation || !this.variation.dynamic_pricing) {
      return false;
    }

    const qty = this.quantity;

    const mixingFee = this.mixingFee;

    const tubePrice = this.tubePrice;

    const variationRule = this.variation.dynamic_pricing
      .find(({ from, to }) => isBetween(from, to, qty));

    if (!variationRule) {
      return false;
    }

    return getCurrency(variationRule.price * qty + mixingFee + tubePrice);
  }

  get mixingFee()
  {
    if (!this.isCustom()) {
      return 0;
    }

    const mixingFee = this._group.data('mixingFee');

    if (!mixingFee) {
      return 0;
    }

    return mixingFee;
  }

  get groupId()
  {
    return this._group.closest('.product-type-grouped').data('group');
  }

  get productId()
  {
    return this._group.data('productId');
  }

  get productAttributes()
  {
    return this._group.data('productAttributes');
  }

  get quantity()
  {
    const input = this.quantityInput;
    const val = parseInt(input.val());

    if (val === false || isNaN(val)) {
      return 0;
    }

    return val;
  }

  get customInput()
  {
    return this._group.find('#custom_mix');
  }

  get quantityInput()
  {
    return this._group.find('.input-text.qty');
  }

  get variation()
  {
    return this._productVariation;
  }

  set variation(variation)
  {
    if (!variation || (!variation.id && !variation.price)) {
      this._productVariation = false;
    }

    this._productVariation = variation;
  }

  get tubePrice()
  {
    if (!this.isCombo()) {
      return 0;
    }

    const price = this._group.data('tubePrice');

    const qty = this.tubeQuantity;

    return price * qty;
  }

  get tubeQuantityInput()
  {
    return this._group.find('.input-additional-tubes');
  }

  get totalTubeQuantity()
  {
    return this.quantity + this.tubeQuantity;
  }

  get tubeQuantity()
  {
    if (!this.isCombo()) {
      return 0;
    }

    const val = parseInt(this.tubeQuantityInput.val());

    if (val === false || isNaN(val)) {
      return 0;
    }

    return val;
  }

  postCart(product)
  {
    const action = 'kampel_add_to_cart';

    this.loading = true;

    return api.post(action, product)
      .then(({ data }) => data)
      .catch(({ message }) => {
        console.log('catch', message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  addToCart()
  {
    const data = {
      group_id: this.groupId,
      product_id: this.productId,
      quantity: this.quantity,
    };

    if (this.productId != this.variation.id) {
      data.variation_id = this.variation.id;
    }

    if (this.isCustom()) {
      data.custom_mix = this.customInput.val();
    }

    if (this.isCombo()) {
      data.additional_tubes = this.tubeQuantityInput.val();
    }

    this.postCart(data)
      .then(({ cart_url }) => {
        window.location.href = cart_url;
      });
  }

  clearForm()
  {
    this._group.find('.input-text, .input-textarea, .input-select').each((i, el) => {
      const input = $(el);

      if (input.hasClass('input-text') || input.hasClass('input-textarea')) {
        input.val('');
      }

      if (input.hasClass('input-select')) {
        input.val('')
          .prop('disabled', !!i)
          .trigger('change.select2');
      }

      if (input.hasClass('qty') || input.hasClass('input-number')) {
        input.val(0).trigger('input');
      }
    });

    if (this._group.find('.input-select').length) {
      this._group.find('.qty, .input-number').prop('disabled', true);
    }

    this._group.find('.single_add_to_cart_button').prop('disabled', true);
  }

  enableInput(input)
  {
    input.prop('disabled', false);
  }

  enableCustomInput()
  {
    if (!this.isCustom()) {
      return;
    }

    this.enableInput(this.customInput);
  }

  enableTubeQuantityInput()
  {
    console.log(this.tubeQuantityInput);
    if (!this.isCombo()) {
      return;
    }

    this.enableInput(this.tubeQuantityInput);
  }

  enableQuantityInput()
  {
    this.enableInput(this.quantityInput);
  }

  init()
  {
    this._group.on('change', '.product-attribute', (e) => {
      this.onAttributeChange(e.currentTarget);
    });

    this._group.on('input', '.input-text.qty', () => {
      this.updateTotalTubes();
      this.updatePrice();
      this.validate();
    });

    this._group.on('input', '.input-additional-tubes', () => {
      this.updateTotalTubes();
      this.updatePrice();
    });

    this._group.on('input', '.input-textarea', () => {
      this.validate();
    });

    this._group.on('click', '.single_add_to_cart_button', () => {
      this.addToCart();
    });
  }

  isCombo()
  {
    return this.tubeQuantityInput.length;
  }

  isCustom()
  {
    return this._group.hasClass('-is-custom');
  }

  isLastAttribute(name)
  {
    const idx = this.productAttributes.findIndex((attr) => attr === name);

    if (idx === -1) return false;

    return idx == this.productAttributes.length - 1;
  }

  isGroupProduct(el)
  {
    return el.hasClass('group-product');
  }

  getNextAttribute(currentAttribute)
  {
    if (this.isLastAttribute(currentAttribute)) {
      return false;
    }

    const idx = this.productAttributes.findIndex((attr) => attr === currentAttribute);

    return this.productAttributes[idx+1];
  }

  fetchAttributeOptions()
  {
    const action = 'kampel_get_product_attributes';
    const data = {
      attributes: JSON.stringify(this._attributeValues),
      product_id: this.productId,
    };

    this.loading = true;

    return api.post(action, data)
      .then(({ data }) => data)
      .catch(({ message }) => {
        console.log('catch', message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getAttributeOptions()
  {
    this.fetchAttributeOptions()
      .then((data) => this.setAttributeOptions(data));
  }

  fetchProductVariation()
  {
    const action = 'kampel_get_product_variation';
    const data = {
      attributes: JSON.stringify(this._attributeValues),
      product_id: this.productId,
    };

    this.loading = true;

    return api.post(action, data)
      .then(({ data }) => data)
      .catch(({ message }) => {
        console.log('catch', message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getProductVariation()
  {
    return this.fetchProductVariation()
      .then((data) => this.variation = data);
  }

  onAttributeChange(input)
  {
    const name = $(input).data('attributeName');
    const value = $(input).val();

    this.setProductAttributeValue(name, value);

    this.resetSucceeding(name);

    if (this.isLastAttribute(name)) {
      this.getProductVariation()
        .then(() => {
          this.enableCustomInput();
          this.enableQuantityInput();
          this.enableTubeQuantityInput();
          this.setQuantity(1);
        });
      return;
    }

    this.getAttributeOptions();
  }

  findAttributeInputByName(attributeName)
  {
    const input = this._group.find(`[data-attribute-name="${attributeName}"]`);

    if (!input.length) throw new Error('Input not found');

    return input;
  }

  setAttributeOptions({attribute, values})
  {
    const input = this.findAttributeInputByName(attribute);

    input.html('<option value="">Select an option</option>')
    input.append(
      values
        .map(({ label, value }) => `<option value="${value}">${label}</option>`)
        .join('')
    );

    this.enableInput(input);

    input.trigger('change.select2');
  }

  resetSucceeding(attributeName)
  {
    const idx = this.productAttributes.findIndex((attr) => attr === attributeName);

    if (idx === -1) return;

    for (let i = 0; i < this.productAttributes.length; i++) {
      if (i <= idx) continue;

      const key = this.productAttributes[i];

      const input = this.findAttributeInputByName(key);

      input.val('').prop('disabled', true).trigger('change.select2');

      if (this.isLastAttribute(key)) {
        this.setQuantity(0);
        this.quantityInput.prop('disabled', true);
      }

      delete this._attributeValues[key];
    }
  }

  setProductAttributeValue(name, value)
  {
    this._attributeValues[name] = value;
  }

  setQuantity(qty)
  {
    this.quantityInput.val(qty).trigger('input');
  }

  updatePrice()
  {
    const priceEl = this._group.find('.woocommerce-group-tabs-content-price');
    const price = this.price;
    const salePrice = this.salePrice;

    if (!salePrice) {
      priceEl.html(price);
      return;
    }

    priceEl.html(`<s>${price}</s> ${salePrice}`);
  }

  updateTotalTubes()
  {
    if (!this.isCombo()) {
      return;
    }

    this._group.find('.total-tubes-count').html(this.totalTubeQuantity);
  }

  validate()
  {
    const hasQuantity = !!this.quantity;

    let isValid = hasQuantity;

    if (this.isCustom()) {
      const hasMix = this._group.find('#custom_mix').val() != '';
      isValid = isValid && hasMix;
    }

    this._group.find('.single_add_to_cart_button')
      .prop('disabled', !isValid);
  }
}
