export default class Accordions {
  constructor() {
    this.initEvents();
  }
  initEvents() {
    $( '.accordion__container' ).addClass( 'accordion__container--loaded' );
    $( '.accordion__single .accordion__single__question' ).on( 'click', (e) => this.onButtonClick(e) );
  }
  onButtonClick(e) {
    const accordionButton = $( e.currentTarget );
    const parentContainer = accordionButton.parent();
    const grandparentContainer = parentContainer.parent();

    if( grandparentContainer.hasClass( 'accordion__single--open' ) ) {
      grandparentContainer.removeClass( 'accordion__single--open' ).addClass( 'accordion__single--closed' )
    } else {
      $( '.accordion__single' ).removeClass( 'accordion__single--open' ).addClass( 'accordion__single--closed' )
      grandparentContainer.removeClass( 'accordion__single--closed' ).addClass( 'accordion__single--open' );
    }
  }
}