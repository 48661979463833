import sendProductsToCart from '../util/sendProductsToCart';

class mixFinder {
    constructor() {
        this.ajaxInfo = window.mixSelect;
        this.isLookingUpMix = false;
        this._form = document.getElementById( 'findMixForm' );
        this._formSubmit = document.getElementById( 'findMixSubmit' );
        this._formReset = document.getElementById( 'findMixReset' );
        this._brandSelect = document.getElementById( 'findMixManufacturer' );
        this._numberInput = document.getElementById( 'findMixColorCode' );
        this._colorInput = document.getElementById( 'findMixColorName' );
        this._productTotalPrice = document.getElementById( 'findMixTotalPrice' );
        this._addToCartButton = document.getElementById( 'findMixAddToCart' );
        this._resultsSection = document.getElementById( 'mixFindResults' );
        this.initEvents();
    }
    initEvents() {
        $( this._brandSelect ).select2();
        $( this._brandSelect ).on( 'change', e => this.brandChange(e) );

        this._form.addEventListener( 'submit', e => this.mixLookup(e) );
        this._formReset.addEventListener( 'click', e => this.resetForm(e) );
        this._numberInput.addEventListener( 'blur', e => this.colorNumberUpdate(e) );
        this._colorInput.addEventListener( 'blur', e => this.colorNameUpdate(e) );
        this._addToCartButton.addEventListener( 'click', e => this.addMixProductsToCart(e) );

        $( this._formReset ).on( 'show_form_reset', e => this.showReset(e) );
        $( this._productTotalPrice ).on( 'update_mix_price', e => this.updateTotalPrice(e) );
    }
    mixLookup(e) {
        e.preventDefault();
        const getColorCode = document.getElementById( 'findMixColorCode' ).value;
        
        if( !this.isLookingUpMix ) {
            if( getColorCode && getColorCode !== 'Choose Color Code' ) {
                $( this._form ).addClass( 'find-mix__form--active' );
                this.isLookingUpMix = true;
                this._formSubmit.disabled = true;
                
                let bodyData = new FormData();
                bodyData.append( 'action', 'kampel_find_mix' );
                bodyData.append( 'color_code', getColorCode );
                bodyData.append( 'nonce', this.ajaxInfo.nonce );
    
                fetch( this.ajaxInfo.ajax_url, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                    },
                    body: new URLSearchParams( bodyData ),
                })
                .then( response => response.json() )
                .then( data => {
                    // this.populateMixTable( data.results );
                    this.populateMatchingProducts( data.html );
                    this._resultsSection.style.display = 'block';
                    this.isLookingUpMix = false;
                    this._formSubmit.disabled = false;
                    $( this._form ).removeClass( 'find-mix__form--active' );
                    $( this._form ).addClass( 'find-mix__form--search' );
                    $( '.find-mix__product-text' ).show();
                })
                .then( () => {
                    $( '.matching-product-select' ).on( 'change', (e) => this.productVariationChange(e) );
                    $( '.matching-product-quantity input[name="quantity"]' ).on( 'keyup mouseup', (e) => this.productQuantityChange(e) );
                    $( '.matching-product-select' ).select2();
                })
                .catch( err => console.error( err ) )
            }
        }
    }
    brandChange(e) {
        $( this._formReset ).trigger( 'show_form_reset' );
        $( this._form ).addClass( 'find-mix__form--active' );

        const colorCodeContainer = document.getElementById('findMixOptionCode');
        const colorNameContainer = document.getElementById('findMixOptionName');
        const colorCodeSelect = document.createElement('select');
        const colorNameSelect = document.createElement('select');

        colorCodeSelect.setAttribute('id', 'findMixColorCode');
        colorCodeSelect.setAttribute('name', 'findMixColorCode');
        colorNameSelect.setAttribute('id', 'findMixColorName');
        colorNameSelect.setAttribute('name', 'findMixColorName');

        colorCodeContainer.querySelector('#findMixColorCode').remove();
        colorCodeContainer.append(colorCodeSelect);

        colorNameContainer.querySelector('#findMixColorName').remove();
        colorNameContainer.append(colorNameSelect);

        $( colorCodeSelect ).select2();
        $( colorNameSelect ).select2();
        $( colorCodeSelect ).on( 'change', (e) => this.colorCodeSelectChange(e) );
        $( colorNameSelect ).on( 'change', (e) => this.colorNameSelectChange(e) );

        this.getBrandColorCodes(e.target.value, colorCodeSelect, colorNameSelect);
    }
    colorNumberUpdate(e) {
        $( this._formReset ).trigger( 'show_form_reset' );

        let bodyData = new FormData();
        bodyData.append( 'action', 'kampel_text_color_lookup' );
        bodyData.append( 'color_update_meta', 'color_number' );
        bodyData.append( 'color_update_value', e.target.value );
        bodyData.append( 'nonce', this.ajaxInfo.nonce );

        if( e.target.value ) {
            $( this._form ).addClass( 'find-mix__form--active' );
            fetch( this.ajaxInfo.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: new URLSearchParams( bodyData ),
            })
            .then( response => response.json() )
            .then( data => {
                if( data.length === 1 ) {
                    const colorData = data[0];
                    $( this._brandSelect ).val( colorData.manufacturer.slug ).trigger( 'change.select2' );
                    this._numberInput.value = colorData.color_number;
                    this._colorInput.value = colorData.color_name;
                }
                $( this._form ).removeClass( 'find-mix__form--active' );
            })
            .catch( err => console.error( err ) )
        }
    }
    colorNameUpdate(e) {
        $( this._formReset ).trigger( 'show_form_reset' );

        const colorBrandContainer = document.getElementById('findMixOptionManufacturer');
        const colorCodeContainer = document.getElementById('findMixOptionCode');

        let bodyData = new FormData();
        bodyData.append( 'action', 'kampel_text_color_lookup' );
        bodyData.append( 'color_update_meta', 'color_name' );
        bodyData.append( 'color_update_value', e.target.value );
        bodyData.append( 'nonce', this.ajaxInfo.nonce );

        if( e.target.value ) {
            $( this._form ).addClass( 'find-mix__form--active' );
            fetch( this.ajaxInfo.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: new URLSearchParams( bodyData ),
            })
            .then( response => response.json() )
            .then( data => {
                if( data.length === 1 ) {
                    const colorData = data[0];
                    this._brandSelect.value = colorData.manufacturer.slug;
                    this._numberInput.value = colorData.color_number;
                    this._colorInput.value = colorData.color_name;
                } else {
                    let colorBrandSelect = document.createElement('select');
                    let colorCodeSelect = document.createElement('select');
                    
                    colorBrandSelect.setAttribute('id', 'findMixManufacturer');
                    colorBrandSelect.setAttribute('name', 'findMixManufacturer');
                    colorCodeSelect.setAttribute('id', 'findMixColorCode');
                    colorCodeSelect.setAttribute('name', 'findMixColorCode');

                    colorBrandContainer.querySelector('#findMixManufacturer').remove();
                    colorCodeContainer.querySelector('#findMixColorCode').remove();
                    colorBrandContainer.append(colorBrandSelect);
                    colorCodeContainer.append(colorCodeSelect);

                    $( colorBrandSelect ).select2();
                    $( colorCodeSelect ).select2();
                    $( colorBrandSelect ).on( 'change', (e) => $( '#findMixColorCode' ).val( e.target.options[e.target.selectedIndex].getAttribute('colorNumber') ).trigger( 'change.select2' ) );
                    $( colorCodeSelect ).on( 'change', (e) => $( '#findMixManufacturer' ).val( e.target.options[e.target.selectedIndex].getAttribute('colorBrand') ).trigger( 'change.select2' ) );

                    data.map( color => {
                        let colorBrandOption = document.createElement( 'option' );
                        let colorCodeOption = document.createElement( 'option' );

                        colorBrandOption.text = color.manufacturer.name;
                        colorCodeOption.text = color.color_number;
                        colorBrandOption.value = color.manufacturer.slug;
                        colorCodeOption.value = color.color_number;
                        colorBrandOption.setAttribute( 'colorNumber', color.color_number );
                        colorCodeOption.setAttribute( 'colorBrand', color.manufacturer.slug );
    
                        colorBrandSelect.add( colorBrandOption, null );
                        colorCodeSelect.add( colorCodeOption, null );
                    });

                    $( this._form ).removeClass( 'find-mix__form--active' );
                    $( colorBrandSelect ).select2();
                    $( colorCodeSelect ).select2();
                }
            })
            .catch( err => console.error( err ) )
        }
    }
    getBrandColorCodes(brandSelected, colorCodeSelect, colorNameSelect) {
        let colorCodeDefaultOption = document.createElement( 'option' );
        let colorNameDefaultOption = document.createElement( 'option' );

        let bodyData = new FormData();
        bodyData.append( 'action', 'kampel_mix_brand_update' );
        bodyData.append( 'brand', brandSelected );
        bodyData.append( 'nonce', this.ajaxInfo.nonce );

        colorCodeDefaultOption.text = 'Choose Color Code';
        colorNameDefaultOption.text = 'Choose Color Name';

        colorCodeSelect.add( colorCodeDefaultOption, null );
        colorNameSelect.add( colorNameDefaultOption, null );
        
        fetch( this.ajaxInfo.ajax_url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams( bodyData ),
        })
        .then( response => response.json() )
        .then( data => {
            data.map( color => {
                let colorCodeOption = document.createElement( 'option' );
                let colorNameOption = document.createElement( 'option' );

                colorNameOption.text = color.color_name;
                colorCodeOption.text = color.color_number;
                colorNameOption.value = color.color_name;
                colorCodeOption.value = color.color_number;
                colorNameOption.setAttribute( 'colorNumber', color.color_number );
                colorCodeOption.setAttribute( 'colorName', color.color_name );

                colorCodeSelect.add( colorCodeOption, null );
                colorNameSelect.add( colorNameOption, null );
            })
        })
        .then( () => {
            $( this._form ).removeClass( 'find-mix__form--active' );
            $( colorCodeSelect ).select2();
            $( colorNameSelect ).select2();
        })
        .catch( err => console.error( err ) )
    }
    productVariationChange(e) {
        const selectParentContainer = $( e.currentTarget ).parents( '.matching-product-container' );
        const productQuantityInput = $( selectParentContainer ).find( '.matching-product-quantity' );
        const productQuantityHiddenInput = $( selectParentContainer ).find( '.input-matching-product-quantity' );
        const productVariationInput = $( selectParentContainer ).find( '.input-matching-product-variation' );
        const productPriceInput = $( selectParentContainer ).find( '.input-matching-product-price' );

        productQuantityHiddenInput.val( 0 );
        productQuantityInput.find( 'input[name="quantity"]' ).val( 0 );
        
        if( e.target.value ) {
            productQuantityInput.show();
            productVariationInput.val( e.target.value );
            productPriceInput.val( $( e.currentTarget ).find( 'option:selected' ).data( 'price' ) );
        } else {
            productQuantityInput.hide();
            productVariationInput.val( 0 );
            productPriceInput.val( 0 );
        }

        $( this._productTotalPrice ).trigger( 'update_mix_price' );
    }
    productQuantityChange(e) {
        const selectParentContainer = $( e.currentTarget ).parents( '.matching-product-container' );
        const productQuantityInput = $( selectParentContainer ).find( '.input-matching-product-quantity' );
        
        productQuantityInput.val( e.target.value );
        $( this._productTotalPrice ).trigger( 'update_mix_price' );
    }
    updateTotalPrice() {
        const productContainers = $( '.matching-product-container' );
        let totalPrice = 0;
        let discountProdPrice = 0;
        productContainers.each( (i, el) => {
            const productContainer = $( el );
            const rowQuantityInput = productContainer.find( '.input-matching-product-quantity' );
            const rowPriceInput = productContainer.find( '.input-matching-product-price' );
            const rowPrice = rowQuantityInput.val() * rowPriceInput.val();            
            const parentProductId = productContainer.find( '.input-matching-product-parent' ).val();
            const variationId = productContainer.find( '.input-matching-product-variation' ).val();

            let theUpdatedPrice = 0;
            let priceReduced = false;
            
            $('.dynamic_price_block-' + parentProductId).each(function () {
                 var fromRange = $(this).data('qfrom');
                 var toRange = $(this).data('qto');
                 if (rowQuantityInput.val() == fromRange || rowQuantityInput.val() == toRange || (rowQuantityInput.val() > fromRange && rowQuantityInput.val() < toRange)) {
                    discountProdPrice = discountProdPrice + ( (rowPriceInput.val() - $(this).val()) * rowQuantityInput.val())                    
                    theUpdatedPrice = $(this).val();   
                    priceReduced = true;                  
                 } else {
                     if(!priceReduced) {
                        theUpdatedPrice = rowPriceInput.val();    
                     }  
                 } 
                 let upPR = parseFloat(theUpdatedPrice);           
                 productContainer.find('.woocommerce-Price-amount').html('$'+upPR.toFixed(2));
            });
            
            if(!priceReduced) {
                $('.dynamic_price_block-' + variationId).each(function () {
                    var fromRange = $(this).data('qfrom');
                    var toRange = $(this).data('qto');
                    if (rowQuantityInput.val() == fromRange || rowQuantityInput.val() == toRange || (rowQuantityInput.val() > fromRange && rowQuantityInput.val() < toRange)) {
                       discountProdPrice = discountProdPrice + ( (rowPriceInput.val() - $(this).val()) * rowQuantityInput.val())                    
                       theUpdatedPrice = $(this).val();   
                       priceReduced = true;                  
                    } else {
                        if(!priceReduced) {
                           theUpdatedPrice = rowPriceInput.val();    
                        }  
                    } 
                    let upPR = parseFloat(theUpdatedPrice);           
                    productContainer.find('.woocommerce-Price-amount').html('$'+upPR.toFixed(2));
               });

            }







            totalPrice += rowPrice;
        });

        if( totalPrice > 0 ) {
            $( '#findMixAddToCart' ).attr( 'disabled', false );
        } else {
            $( '#findMixAddToCart' ).attr( 'disabled', true );
        }
        if (discountProdPrice > 0) {
            const totalDiscPrice = totalPrice - discountProdPrice;
            const oldPrice = '<span style="color:#f15e22;float:left; padding-right: 8px !important;text-decoration: line-through;">$' + totalPrice.toFixed(2) + ' </span>';
            $( '#findMixTotalPrice' ).html(oldPrice + '  $' + totalDiscPrice.toFixed(2));
        } else {
            $( '#findMixTotalPrice' ).html('$'+totalPrice.toFixed(2) );
        }
        //$( '#findMixTotalPrice i' ).html( totalPrice.toFixed(2) );
    }
    addMixProductsToCart() {
        const productContainers = $( '.matching-product-container' );
        const cartItems = [];

        this._addToCartButton.disabled = true;

        for( let i = 0; i < productContainers.length; i++ ) {
            const productContainer = $( productContainers[i] );
            const rowParentInput = productContainer.find( '.input-matching-product-parent' );
            const rowVariationInput = productContainer.find( '.input-matching-product-variation' );
            const rowQuantityInput = productContainer.find( '.input-matching-product-quantity' );

            if( rowParentInput.length <= 0 && rowVariationInput.length <= 0 && rowQuantityInput.length <= 0 )
                continue

            const parentId = rowParentInput.val();
            const variationId = rowVariationInput.val();
            const quantityInput = rowQuantityInput.val();

            if( isNaN( quantityInput ) || quantityInput <= 0 )
                continue
                
            const cartItemData = {
                'parent_id': parentId,
                'variation_id': variationId,
                'quantity': quantityInput,
            };

            cartItems.push( cartItemData );
        }

        if( cartItems.length > 0 ) {
            sendProductsToCart( cartItems )
            .then( data => location.href = data.data.cart_url )
            .then( () => this._addToCartButton.disabled = false );
        }
    }
    populateMixTable( tableData ) {
        document.getElementById('keiMixManufacturer').innerHTML = tableData.manufacturer;
        document.getElementById('keiMixColor').innerHTML = tableData.color_name;
        document.getElementById('keiMixNumber').innerHTML = tableData.color_number;
        // document.getElementById('keiMixCode').innerHTML = tableData.kei_number;
        document.getElementById('keiMixOne').innerHTML = tableData.mix_one;
        document.getElementById('keiMixHalf').innerHTML = tableData.mix_half_first;
        document.getElementById('keiMixHalfSecond').innerHTML = tableData.mix_half_second;
        document.getElementById('keiMixQuarter').innerHTML = tableData.mix_quarter_first;
        document.getElementById('keiMixQuarterSecond').innerHTML = tableData.mix_quarter_second;
        document.getElementById('keiMixThreeQuarter').innerHTML = tableData.mix_three_quarter;
        document.getElementById('keiMixThird').innerHTML = tableData.mix_third_first;
        document.getElementById('keiMixThirdSecond').innerHTML = tableData.mix_third_second;
        document.getElementById('keiMixThirdThird').innerHTML = tableData.mix_third_third;
        document.getElementById('keiMixTwoThird').innerHTML = tableData.mix_two_third;
        document.getElementById('keiMixSlightTouch').innerHTML = tableData.mix_slight_touch;
        document.getElementById('keiMixSpeck').innerHTML = tableData.mix_speck;
    }
    populateMatchingProducts( productHtml ) {
        document.getElementById('keiMixMatchingProducts').innerHTML = productHtml;
    }
    showReset() {
        this._formReset.style.display = 'block';
        this._formSubmit.classList.remove('disabled');
        this._resultsSection.style.display = 'none';
    }
    resetForm() {
        const currentBrandInput = $( '#findMixManufacturer' );
        const currentColorCodeInput = $( '#findMixColorCode' );
        const currentColorNameInput = $( '#findMixColorName' );

        if( currentBrandInput.hasClass('select2-hidden-accessible') )
            currentBrandInput.select2('destroy');

        if( currentColorCodeInput.hasClass('select2-hidden-accessible') )
            currentColorCodeInput.select2('destroy');
        
        if( currentColorNameInput.hasClass('select2-hidden-accessible') )
            currentColorNameInput.select2('destroy');
        
        currentBrandInput.remove();
        currentColorCodeInput.remove();
        currentColorNameInput.remove();
        
        this._brandSelect.value = '';
        this._numberInput.value = '';
        this._colorInput.value = '';
        $( '#findMixOptionManufacturer' ).append( this._brandSelect );
        $( '#findMixOptionCode' ).append( this._numberInput );
        $( '#findMixOptionName' ).append( this._colorInput );

        this.initEvents();
        $( this._productTotalPrice ).trigger( 'update_mix_price' );
        $( '.find-mix__product-text' ).hide();
        this._resultsSection.style.display = 'none';
        this._formSubmit.classList.add('disabled');
        this._formReset.style.display = 'none';
        this._form.classList.remove('find-mix__form--search');
    }
    colorCodeSelectChange(e) {
        const getColorName = e.target.options[e.target.selectedIndex].getAttribute('colorName');
        $( '#findMixColorName' ).val( getColorName ).trigger( 'change.select2' );
    }
    colorNameSelectChange(e) {
        const getColorNumber = e.target.options[e.target.selectedIndex].getAttribute('colorNumber');
        $( '#findMixColorCode' ).val( getColorNumber ).trigger( 'change.select2' );
    }
}

export default {
    init() {
        // JavaScript to be fired on the Find Your Mix page
        new mixFinder();
    },
    finalize() {
    },
};