import GroupedProducts from '../components/GroupedProducts/GroupedProducts';
import SimpleProduct from '../components/SimpleProduct/SimpleProduct';
import ProductGallery from '../components/productGallery';


export default {
    init() {
        if ($('.group-product').length) {
            const productSelect = new GroupedProducts();
            productSelect.init();
        }

        if ($('.woocommerce-simple-form').length) {
            const simpleProduct = new SimpleProduct($('.woocommerce-simple-form'));
            simpleProduct.init();
            simpleProduct.clearForm();
        }

        if ($('woocommerce-product-gallery').length > -1) new ProductGallery();

        $('.input-select').select2();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
