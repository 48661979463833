
export default cartData => {
    let bodyData = new FormData();
        bodyData.append( 'action', 'kampel_send_cart_data' );
        bodyData.append( 'cart_items', JSON.stringify(cartData) );
        bodyData.append( 'nonce', window.mixSelect.nonce );
        
        return fetch( window.mixSelect.ajax_url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: new URLSearchParams( bodyData ),
        })
        .then( response => response.json() )
        // .then( data => location.href = data.data.cart_url )
        .catch( err => console.error( err ) );
}