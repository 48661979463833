
export default class ProductGallery {
  constructor() {
    this.initEvents();
  }
  initEvents() {
    $( '.woocommerce-product-gallery__thumbnail-container .woocommerce-product-gallery__image:first-child' ).addClass( 'woocommerce-product-gallery__image--selected' );
    $( '.woocommerce-product-gallery__thumbnail-container .woocommerce-product-gallery__image a' ).on( 'click', (e) => this.changeImage(e) )
  }
  changeImage(e) {
    const currentImage = $( e.currentTarget ).parent();
    const currentImageHtml = currentImage.html();
    $( '.woocommerce-product-gallery__main' ).html( currentImageHtml );
    $( '.woocommerce-product-gallery__thumbnail-container .woocommerce-product-gallery__image' ).removeClass( 'woocommerce-product-gallery__image--selected' );
    currentImage.addClass( 'woocommerce-product-gallery__image--selected' );
    e.preventDefault();
  }
}
