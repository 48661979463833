import Axios from 'axios';

export default class Api
{
  constructor()
  {
    this._api = Axios;
  }

  post(action = '', data = {})
  {
    if (!action) {
      throw new Error('Action was not supplied');
    }

    const formData = new FormData();

    formData.append('action', action);

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return this.fetch(formData);
  }

  fetch(data, method = 'post')
  {
    if (data instanceof FormData) {
      data.append('_wpnonce', window.mixSelect.nonce);
    } else {
      data._wpnonce = window.mixSelect.nonce;
    }

    return this._api({
      url: window.mixSelect.ajax_url,
      method,
      data,
    })
      .then(({ data }) => data)
      .catch(({ response }) => {
        throw new Error(response.data.data.message);
      });
  }
}
