import getCurrency from '../../util/getCurrency';

export default class SimpleProduct
{
  constructor(product)
  {
    product = $(product);

    if (!this.isProduct(product)) {
      throw new Error('Element is not a product');
    }

    this._product = product;
  }

  get price()
  {
    const qty = this.quantity;

    if (!this.quantity) {
      return getCurrency(0);
    }

    const price = this._product.data('price');

    return getCurrency(price * qty);
  }

  get quantityInput()
  {
    return this._product.find('.input-text.qty');
  }

  get quantity()
  {
    const val = parseInt(this.quantityInput.val());

    if (val === false || isNaN(val)) {
      return 0;
    }

    return val;
  }

  clearForm()
  {
    this.quantityInput.val('1').trigger('input');
  }

  init() {
    this._product.on('input', '.input-text.qty', () => {
      this.updatePrice();
    });
  }

  isProduct(product)
  {
    return product.hasClass('woocommerce-simple-form');
  }

  updatePrice()
  {
    const priceEl = this._product.find('.woocommerce-Price-amount');

    priceEl.html(this.price);
  }
}
