export default class ProductCategories {
  constructor() {
    this.initEvents();
  }
  initEvents() {
    $( '.sidebar-area' ).addClass( 'sidebar-area--loaded' );
    $( '.widget h3' ).on( 'click', (e) => this.onButtonClick(e) );

    $( '.current-cat' ).parents('.widget').addClass( 'widget--open' );
  }
  onButtonClick(e) {
    const accordionButton = $( e.currentTarget );
    const parentContainer = accordionButton.parent();

    if( parentContainer.hasClass( 'widget--open' ) ) {
      parentContainer.removeClass( 'widget--open' ).addClass( 'widget--closed' )
    } else {
      $( '.widget' ).removeClass( 'widget--open' ).addClass( 'widget--closed' )
      parentContainer.removeClass( 'widget--closed' ).addClass( 'widget--open' );
    }
  }
}