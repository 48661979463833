import GroupProduct from '../GroupProduct/GroupProduct';

export default class GroupedProducts
{
    init()
    {
        this.groups = $('.group-product').map((i, el) => {
            const productGroup = new GroupProduct(el);
            productGroup.init();
            return productGroup;
        }).get();

        $('.woocommerce-group-tabs-header').on('click', 'button', (e) => {
            this.switchTabs(e);
        });

        this.clearAll();
    }

    clearAll()
    {
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].clearForm();
        }
    }

    checkProduct(i)
    {
        const group = this.groups[i];

        if (!group.variation) {
            group.getProductVariation();
        }
    }

    switchTabs(e)
    {
        const tab = $(e.currentTarget);
        const tabIdx = tab.parent().index();
        const tabId = tab.attr('id');
        const tabContent = $(`.woocommerce-tabs-content-single[data-tab="${tabId}"]`);

        this.clearAll();
        this.checkProduct(tabIdx);
        tab.parent().siblings().removeClass('woocommerce-group-tabs-button--active');
        tab.parent().addClass('woocommerce-group-tabs-button--active');
        tabContent.siblings().hide();
        tabContent.show();
    }
}
