import 'slick-carousel'
const $ = window.jQuery

export default {
  init() {
    // JavaScript to be fired on the home page
    $( '#applicationTemplateSlideshow' ).on( 'init', (e, slick) => {
      const currentSlide = $( slick.$slides[0] );
      const slideBefore = currentSlide.prev();
      const slideAfter = currentSlide.next();
      
      slideBefore.addClass( 'slick-slide-previous' );
      slideAfter.addClass( 'slick-slide-next' );
    });

    $( '#applicationTemplateSlideshow' ).on( 'beforeChange', (e, slick, currentSlide, nextSlide) => {
      const newSlide = $( slick.$slides[nextSlide] );
      const slideBefore = newSlide.prev();
      const slideAfter = newSlide.next();

      $( '.applications__application' ).removeClass( 'slick-slide-previous slick-slide-next' );
      
      slideBefore.addClass( 'slick-slide-previous' );
      slideAfter.addClass( 'slick-slide-next' );
    });

    $( '#applicationTemplateSlideshow' ).slick(window.applicationSlickSettings);
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
