
export default class supportSection {
  constructor() {
    this.ajaxInfo = window.mixSelect;
    this.isLoading = false;
    this.supportContainer = document.querySelector( '.support__container' );
    this.supportBodyClass = 'support-sidebar-open';
    
    this.initEvents()
    if( window.location.hash ) {
      this.openSidebar( null, window.location.hash.substring(1) )
    }
    if( this.supportContainer ) {
      $( '.support-link > a' ).on( 'click', (e) => {
        const currentTarget = $( e.currentTarget );
        const targetLink = currentTarget.attr('href');

        this.openSidebar( null, targetLink.split('#')[1] );
      })
    }
  }
  initEvents() {
    $( '.support .support__cta__link--inline' ).on( 'click', (e) => this.openSidebar(e) );
    $( '.image-ctas .image-ctas__cta__link--inline' ).on( 'click', (e) => this.openSidebar(e) );
  }
  openSidebar(e, action = null) {
    let currentLink;
    if( !action ) {
      currentLink = $( e.currentTarget );
    } else {
      currentLink = $( 'a[data-link="' + action + '"]' )
    }

    if( currentLink.length ) {
      const currentLinkParent = currentLink.parent();
      const sidebarContent = currentLinkParent.find( '.support__cta__sidebar' );
      const linkAction = currentLink.data( 'action' );
      const linkHref = currentLink.data( 'link' );

      
      if( !this.isLoading && linkAction ) {
        this.isLoading = true;

        let sidebarContainer = document.createElement( 'div' );
        sidebarContainer.classList.add( 'support__sidebar' );
        sidebarContainer.setAttribute( 'role', 'complementary' );
        sidebarContainer.innerHTML = sidebarContent.html();
    
        $( '.footer' ).after( sidebarContainer );
        $( sidebarContainer ).find( '.support__sidebar__overlay' ).on( 'click', (e) => this.closeSidebar(e) );
        $( sidebarContainer ).find( '.support__sidebar__close' ).on( 'click', (e) => this.closeSidebar(e) );
        setTimeout( () => {
          $( 'body' ).addClass( this.supportBodyClass );
        }, 250 );

        let bodyData = new FormData();
        bodyData.append( 'action', 'kampel_' + linkAction );
        bodyData.append( 'nonce', this.ajaxInfo.nonce );

        fetch( this.ajaxInfo.ajax_url, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Cache-Control': 'no-cache',
          },
          body: new URLSearchParams( bodyData ),
        })
        .then( response => response.json() )
        .then( data => {
            $( sidebarContainer ).find( '.support__sidebar__content' ).html( data.data )
            this.isLoading = false;
        })
        .then( () => {
          history.pushState( {}, null, '#' + linkAction );
          $( '.support__sidebar .support__faq__section__title' ).on( 'click', (e) => this.toggleSection(e) );
          $( '.support__sidebar .support__faq__single__question' ).on( 'click', (e) => this.toggleQuestion(e) );
        })
        .catch( err => console.error( err ) )
      } else {
        let sidebarContainer = document.createElement( 'div' );
        sidebarContainer.classList.add( 'support__sidebar' );
        sidebarContainer.setAttribute( 'role', 'complementary' );
        sidebarContainer.innerHTML = sidebarContent.html();
    
        $( '.footer' ).after( sidebarContainer );
        $( sidebarContainer ).find( '.support__sidebar__overlay' ).on( 'click', (e) => this.closeSidebar(e) );
        $( sidebarContainer ).find( '.support__sidebar__close' ).on( 'click', (e) => this.closeSidebar(e) );
        setTimeout( () => {
          $( 'body' ).addClass( this.supportBodyClass );
        }, 250 );
        history.pushState( {}, null, '#' + linkHref );
      }
    }
  }
  closeSidebar(e) {
    const currentTarget = $( e.currentTarget );
    const sidebarContainer = currentTarget.parents( '.support__sidebar' );
    
    $( 'body' ).removeClass( this.supportBodyClass );
    history.pushState( '', document.title, window.location.pathname + window.location.search );
    setTimeout( () => {
      sidebarContainer.remove();
    }, 500 );
  }
  toggleSection(e) {
    const currentSection = $( e.currentTarget );
    const getFaqSection = currentSection.data( 'section' );
    $( getFaqSection ).toggleClass( 'support__faq__section__content--open' );
  }
  toggleQuestion(e) {
    const currentQuestion = $( e.currentTarget );
    const getFaqAnswer = currentQuestion.data( 'answer' );
    currentQuestion.toggleClass( 'support__faq__single__question--open' );
    $( getFaqAnswer ).toggleClass( 'support__faq__single__answer--open' );
  }
}