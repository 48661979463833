// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import singleProduct from './routes/single-product';
import templateApplications from './routes/template-applications';
import templateMixData from './routes/template-mix-data';
import templateGuideData from './routes/template-guide-data';
import templateLaminateData from './routes/template-laminate-data';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  singleProduct,
  templateApplications,
  templateMixData,
  templateGuideData,
  templateLaminateData,
});

// Load Events
jQuery(document).on('ready', () => routes.loadEvents());
