import 'slick-carousel'
const $ = window.jQuery

export default {
  init() {
    // JavaScript to be fired on the home page
    $( '#heroSlideshow' ).slick({
      'arrows': false,
      'dots': true,
      'rows': 0,
    });

    if( window.matchMedia( '(max-width: 767px)' ).matches ) {
      $( '.homepage-categories__categories' ).slick({
        'arrows': false,
        'dots': true,
        'rows': 0,
      });
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
